import api from "./api";

type FetchRouteOfRepresentativePayload = {
  msg: string;
  data: [number, number, number, number][];
};

export async function fetchRouteOfRepresentative({
  id,
  date,
}): Promise<FetchRouteOfRepresentativePayload | undefined> {
  try {
    const res = await api.get<FetchRouteOfRepresentativePayload>(
      "/users/sales-representative/coordinates/",
      {
        params: {
          user_id: id,
          date: date,
        },
      }
    );
    return res.data;
  } catch (err) {
    console.log(err);
  }
}
