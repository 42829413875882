/**
 * This is (or should be) a temporary constant
 * Ideally we want to obtain this information from the backend
 */

export const salesRepresentativesInfo = {
  Chile: [
    {
      fullName: "Sandra Cerpa",
      id: "97201d83-265e-4d79-9bcf-6343a0badaeb",
    },
    {
      fullName: "Magdalena Menares",
      id: "3a0266fd-4c7b-456c-b38f-f9cc7043a449",
    },
    {
      fullName: "Jeannette Agüero",
      id: "3030ba29-7583-4aef-957b-a76c6885b899",
    },
    {
      fullName: "María Elena Vargas",
      id: "2bc3107c-6ede-42fb-84c5-2fbdbe07ffb4",
    },
    {
      fullName: "Karen Olivos",
      id: "666a659c-91ed-49f4-b8ba-137bbee60c6a",
    },
    {
      fullName: "Nathalie Adriasola",
      id: "2def3d8c-8022-4ed0-9df8-3c5e8ab1c8cf",
    },
    {
      fullName: "Victor Neira",
      id: "666e5c68-f50e-4135-963d-2bff2c9ab622",
    },
    {
      fullName: "Andrés Pinto",
      id: "5affe0c0-22f1-445c-81ec-a11a4640515f",
    },
    {
      fullName: "Luisa Aguayo",
      id: "f6967078-cff2-4482-93c0-56b88618d1cb",
    },
    {
      fullName: "Marcela Cartagena",
      id: "3c7d3ff1-9b68-4e40-99a4-cce9087b78c0",
    },
    {
      fullName: "Soledad Morales",
      id: "33e4287a-e714-4467-a829-9667fbe2ad78",
    },
    {
      fullName: "Alana Neilson",
      id: "2c9f131f-a543-4f3b-9824-5b87bcc9e26e",
    },
    {
      fullName: "Viviana Lara",
      id: "ab1c6e3d-86b8-4e44-a858-e45c043f74eb",
    },
    {
      fullName: "Militza Tacussis",
      id: "31529a9d-0cad-4a6a-afcb-38a2d0b0fdd7",
    },
    {
      fullName: "Paula Fernandez",
      id: "996056fe-4724-4164-9932-e419962a8dfd",
    },
    {
      fullName: "Pablo Ortiz",
      id: "e038918b-9893-4e80-ad3b-8b334179119f",
    },
    {
      fullName: "Juan Carlos Reyes",
      id: "f19acc06-a55c-4bf9-81f2-2f3883f39756",
    },
    {
      fullName: "Sandra Tapia",
      id: "c91a0132-71ff-4ba1-9cfc-3615b5b94da3",
    },
  ],
  México: [
    {
      fullName: "Odisa Garcia",
      id: "Id vm1",
    },
    {
      fullName: "David Rodríguez",
      id: "Id vm2",
    },
    {
      fullName: "Mario Rodríguez",
      id: "Id vm3",
    },
    {
      fullName: "Cristian Colin",
      id: "Id vm4",
    },
    {
      fullName: "Lorena Ramírez",
      id: "Id vm5",
    },
    {
      fullName: "Helu Palma",
      id: "Id vm6",
    },
  ],
};
