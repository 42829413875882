import React from "react";

import PrivateRoute from "../../components/Authentication/PrivateRoute";
import SalesRepresentativeVisualizer from "../../components/SalesRepresentativeVisualizer/SalesRepresentativeVisualizer";

function SalesRepresentativePage() {
  return (
    <PrivateRoute>
      <SalesRepresentativeVisualizer />
    </PrivateRoute>
  );
}

export default React.memo(SalesRepresentativePage);
