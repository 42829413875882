import React, { useEffect, useState, useRef } from "react";

import styled from "styled-components";

import * as moment from "moment";
import * as L from "leaflet";

import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polyline,
} from "react-leaflet";
import { Button, InputLabel, MenuItem, Select } from "@material-ui/core";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import { COUNTRIES } from "../../utils/data/constants";
import { salesRepresentativesInfo } from "../../utils/constants/salesRepresentativesInfo";
import { fetchRouteOfRepresentative } from "../../utils/api/salesRepresentativesCoordinates";
import LoaderErrorContainer from "../Containers/LoaderErrorContainer";

const FullWidthHeightDiv = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
`;

const FiltersDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4rem;
`;

const PrimaryButton = styled(Button)`
  max-height: 2.5rem;
  border-color: var(--primary);
  color: var(--primary);
`;

function SalesRepresentativeVisualizer() {
  const [loading, setLoading] = useState<boolean>(false);
  const [country, setCountry] = useState<"Chile" | "México">("Chile");
  const [selectedVisitadorId, setSelectedVisitadorId] = useState<string>(
    salesRepresentativesInfo[country][0].id
  );
  const [center, setCenter] = useState<L.LatLng>(
    L.latLng(-33.41385135961599, -70.60334467295577)
  );
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [pointsToShow, setPointsToShow] = useState<
    [number, number, number, number][]
  >([]);
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment);
  const mapRef = useRef<L.Map>(null);

  const onCountryChangeHandler = (e) => {
    setErrorMessage("");
    if (e.target.value === "Chile") {
      setCenter(L.latLng(-33.41385135961599, -70.60334467295577)); // El Bosque Norte 500
    } else {
      setCenter(L.latLng(19.41321796604331, -99.16440678088462)); // San Luis Potosí 196
    }
    mapRef.current?.setView(center, 12);
    setCountry(e.target.value);
  };

  const onVisitadorChangeHandler = (e) => {
    setErrorMessage("");
    setSelectedVisitadorId(e.target.value);
  };

  const onDateChangerHandler = (date: React.SetStateAction<moment.Moment>) => {
    setErrorMessage("");
    setSelectedDate(date);
  };

  const handleFilterButton = async () => {
    setLoading(true);
    try {
      const res = await fetchRouteOfRepresentative({
        id: selectedVisitadorId,
        date: selectedDate.format().slice(0, 10),
      });
      if (res) {
        if (res.data.length > 0) {
          setPointsToShow(res.data);
          setCenter(L.latLng(pointsToShow[0].slice(0, 2)));
          mapRef.current?.setView(center);
        } else if (res.data.length === 0) {
          setErrorMessage("No hay datos para este día");
          setPointsToShow([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (country === "Chile") {
      setCenter(L.latLng(-33.41385135961599, -70.60334467295577)); // El Bosque Norte 500
    } else {
      setCenter(L.latLng(19.41321796604331, -99.16440678088462)); // San Luis Potosí 196
    }
    mapRef.current?.setView(center);
  }, [country]);

  if (typeof window !== "undefined") {
    return (
      <FullWidthHeightDiv>
        <h1>Rutas Visitadores Médicos</h1>
        <FiltersDiv>
          {!loading && (
            <>
              <div>
                <InputLabel>País</InputLabel>
                <Select
                  value={country}
                  label="País"
                  onChange={onCountryChangeHandler}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {COUNTRIES.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel>Visitador</InputLabel>
                <Select
                  value={selectedVisitadorId}
                  label="Visitador"
                  onChange={onVisitadorChangeHandler}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                    getContentAnchorEl: null,
                  }}
                >
                  {salesRepresentativesInfo[country].map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.fullName}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <div>
                <InputLabel>Fecha para buscar</InputLabel>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    format="LL"
                    value={selectedDate}
                    autoOk
                    onChange={onDateChangerHandler}
                    disableFuture
                  />
                </MuiPickersUtilsProvider>
              </div>
              <PrimaryButton
                variant="outlined"
                color="primary"
                onClick={handleFilterButton}
              >
                Filtrar
              </PrimaryButton>
            </>
          )}
          {loading && (
            <LoaderErrorContainer>
              <h1>Cargando...</h1>
            </LoaderErrorContainer>
          )}
        </FiltersDiv>
        {errorMessage.length > 0 && <p>{errorMessage}</p>}
        <div id="map">
          <MapContainer
            center={center}
            zoom={12}
            style={{ height: "600px" }}
            leafletRef={mapRef}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {Array.isArray(pointsToShow) && pointsToShow.length > 0 && (
              <>
                <Polyline
                  positions={pointsToShow.map(
                    (coordinateArray) =>
                      coordinateArray.slice(0, 2) as [number, number]
                  )}
                  pathOptions={{ weight: 8, color: "#457DF1" }}
                />

                {pointsToShow.slice(1, -1).map((point) => (
                  <Marker
                    position={point.slice(0, 2) as [number, number]}
                    eventHandlers={{
                      mouseover: (e: L.LeafletMouseEvent) => {
                        e.target.openPopup(e.latlng);
                        e.target.setPopupContent(
                          `Location is ${e.latlng} and time is ${moment(
                            point[2]
                          ).format("HH:MM:SS")}`
                        );
                      },
                      mouseout: (e: L.LeafletMouseEvent) => {
                        e.target.closePopup();
                      },
                    }}
                  >
                    <Popup />
                  </Marker>
                ))}
                <Marker
                  position={pointsToShow[0].slice(0, 2) as [number, number]}
                  title="Inicio"
                  eventHandlers={{
                    mouseover: (e: L.LeafletMouseEvent) => {
                      e.target.openPopup();
                    },
                    mouseout: (e: L.LeafletMouseEvent) => {
                      e.target.closePopup();
                    },
                  }}
                >
                  <Popup>Inicio del recorrido</Popup>
                </Marker>
                <Marker
                  position={
                    pointsToShow[pointsToShow.length - 1].slice(0, 2) as [
                      number,
                      number
                    ]
                  }
                  eventHandlers={{
                    mouseover: (e: L.LeafletMouseEvent) => {
                      e.target.openPopup();
                    },
                    mouseout: (e: L.LeafletMouseEvent) => {
                      e.target.closePopup();
                    },
                  }}
                  title="Fin"
                >
                  <Popup>Fin del recorrido</Popup>
                </Marker>
              </>
            )}
          </MapContainer>
        </div>
      </FullWidthHeightDiv>
    );
  }
  return (
    <FullWidthHeightDiv>
      <h1>Rutas Visitadores Médicos</h1>
      <h2> cargando...</h2>
    </FullWidthHeightDiv>
  );
}

export default React.memo(SalesRepresentativeVisualizer);
